import MolhamButton from "components/ReuseableCompos/MolhamButton";
import { useModeContext } from "context/ModeContext";
import useMobile from "customHooks/useMobile";
import useModal from "customHooks/useModal";
import { nextCheckoutItemSourceManager } from "customHooks/useNextCheckoutItemSource";
import useRTL from "customHooks/useRTL";
import useWindowSize from "customHooks/useWindowSize";
import useTranslation from "next-translate/useTranslation";
import Image from "next/image";
import { Modal } from "react-bootstrap";
import CountUp from "react-countup";
import styled from "styled-components";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { A11y, Autoplay, EffectFade, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
type Props = {
  promoVid?: string;
};
function HomeIntroSlider({ promoVid }: Props) {
  const { theme } = useModeContext();
  const isRTL = useRTL();
  const slides = [
    "https://cdn.molhamteam.com/assets/homepage-sliders/intro2.png",
    "https://cdn.molhamteam.com/assets/homepage-sliders/intro3.png",
    "https://cdn.molhamteam.com/assets/homepage-sliders/intro0.jpg",
    "https://cdn.molhamteam.com/assets/homepage-sliders/intro1.jpg",
  ];
  const logo =
    "https://cdn.molhamteam.com/assets/img/logo/logo-small-screen.png";
  const { height, width } = useWindowSize();
  const isMobile = useMobile("md");
  const { t } = useTranslation("common");
  const counterData = [
    { title: "cases", value: " 22659" },
    { title: "campaigns", value: " 549" },
    { title: "sponsorships", value: " 4827" },
    { title: "students", value: " 2122" },
  ];
  const sliderBtns = [
    {
      label: "national_initiative",
      href: "/campaigns/658",
      startIcon: "fe-arrow-left",
      endIcon: null,
      color: "success",
    },
    {
      label: "quick_donation",
      href: "#quick_donate",
      startIcon: "fe-heart",
      endIcon: null,
      color: "danger",
    },
    {
      label: "donation_sections",
      href: "/donate",
      startIcon: null,
      endIcon: "fe-arrow-right",
      color: "primary2",
    },
  ];

  const { handleCloseModal, handleShowModal, showModal } = useModal();
  const onPlayClick = () => {
    if (!promoVid) return;
    handleShowModal();
  };
  return (
    <StyledHomeIntroSlider
      isRTL={isRTL}
      className="position-relative user-select-none"
    >
      <Modal
        onHide={handleCloseModal}
        show={showModal}
        centered
        contentClassName="bg-trans border-none shadow-none"
      >
        <iframe width="800" height="300" src={promoVid}></iframe>
      </Modal>
      <div
        className="position-absolute top-50 start-50 translate-middle zindex-2 w-100"
        style={isMobile ? { height: "75%" } : { height: "85%" }}
      >
        <div className="v-centered justify-content-around flex-column w-100 h-100 ">
          <div className="centered flex-column">
            <div
              className={`p-3 mb-3 mb-md-4 rounded-circle text-shadow ${
                promoVid ? "cursor" : ""
              }`}
              onClick={onPlayClick}
              style={{
                // boxShadow: `0 0 1rem 20rem #000`,
                backgroundColor: "#fff",
              }}
            >
              {promoVid ? (
                <i
                  className="fe-play text-primary fs-xxl centered ml-1"
                  style={{ width: "20px", height: "24px" }}
                ></i>
              ) : (
                <Image
                  alt="logo"
                  unoptimized
                  src={logo}
                  width={isMobile ? 50 : 60}
                  height={isMobile ? 50 : 60}
                />
              )}
            </div>
            <h1 className=" mb-3 mb-md-4 w-100 text-center text-white-always text-shadow">
              {t("molham_volunteering_team")}
            </h1>
            <h2 className=" text-center text-white-always text-shadow">
              {t("inspire_humanity")}
            </h2>
          </div>
          {!isMobile && <CounterFormatter counterData={counterData} t={t} />}
          <div className="centered flex-column flex-md-row w-100 gap-4">
            {sliderBtns.map((b, i) => {
              return (
                <StyledSliderBtn
                  color={"var(--" + b.color + ")"}
                  onClick={() => {
                    if (b.href === "/donate") {
                      nextCheckoutItemSourceManager.setNextCheckoutItemSource(
                        nextCheckoutItemSourceManager.options.promo_widget
                      );
                    }
                  }}
                  key={i}
                  id={"slider-" + b.label + "-btn"}
                  labelClassName="fw-bold"
                  startIcon={b.startIcon}
                  endIcon={b.endIcon}
                  variant="light"
                  label={t(b.label)}
                  href={b.href}
                />
              );
            })}
          </div>
        </div>
      </div>
      <StyledSwiper
        modules={[Navigation, A11y, Autoplay, EffectFade]}
        loop
        slidesPerView={1}
        dir={isRTL ? "rtl" : "ltr"}
        theme={theme}
        key={isRTL.toString()}
        effect="fade"
        autoplay={{
          delay: 7500,
        }}
      >
        {slides.map((item, index) => {
          return (
            <SwiperSlide key={index} className="">
              <Image
                className={"image"}
                unoptimized
                src={item}
                alt={"intro"}
                width={width || 1920}
                height={height || 1080}
                style={{
                  height: "85vh",
                  maxHeight: "85vh",
                  objectFit: "cover",
                  transition: "filter 0.3s",
                  filter:
                    theme === "dark" ? "brightness(0.65)" : "brightness(0.9)",
                }}
              />
            </SwiperSlide>
          );
        })}
      </StyledSwiper>
    </StyledHomeIntroSlider>
  );
}

export default HomeIntroSlider;

const CounterFormatter = ({ counterData, t }) => {
  return (
    <div className="centered">
      {counterData.map((item, index) => {
        const isLast = index === counterData.length - 1;
        return (
          <div
            key={index}
            className={`centered text-center flex-column
          ${!isLast && "pe-3 me-3 seperator"}
          
          `}
            style={{ minWidth: "150px" }}
          >
            <CountUp
              className="text-white-always h2 fw-bold"
              end={Number(item.value)}
            />

            <h4 className="text-white-always fw-bold mb-0">{t(item.title)}</h4>
          </div>
        );
      })}
    </div>
  );
};

const StyledSwiper = styled(Swiper)`
  .swiper-pagination {
    width: 100%;
    text-align: center !important;
    bottom: 0 !important;
    @media (max-width: 768px) {
      bottom: 12px !important;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    /* top: 148px !important; */
    background: #fff !important;
    border-radius: 25% !important;
    width: 50px !important;
    height: 50px !important;
    @media (max-width: 767.98px) {
      display: none !important;
    }
  }
  .swiper-button-prev::after {
    margin-inline-end: 4px !important;
    z-index: 3 !important;
  }

  .swiper-button-next {
    margin-inline-start: 4px !important;
    z-index: 3 !important;
  }

  .swiper-button-prev::after,
  .swiper-button-next::after {
    color: var(--primary2) !important;

    font-size: 25px !important;
  }

  .swiper-button-disabled {
    display: none !important;
  }
`;
const StyledSliderBtn = styled(MolhamButton)<{
  color: string;
  key: string | number;
}>`
  color: ${(props) => props.color} !important;
  background-color: #fff;
  &:hover {
    color: #fff !important;
    background-color: ${(props) => props.color} !important;
    border: 1px solid ${(props) => props.color} !important;
  }
  min-width: 250px;

  height: unset !important;
  max-height: unset !important;
  font-size: 18px;
  &:focus {
    background-color: #fff;
    border: 1px solid #fff;
  }
`;
const StyledDSB = styled(MolhamButton)`
  color: var(--primary2) !important;
  background-color: #fff;

  &:hover {
    color: #fff !important;
    background-color: var(--primary2) !important;
    border: 1px solid var(--primary2) !important;
  }
  min-width: 250px;

  height: unset !important;
  max-height: unset !important;
  font-size: 18px;
`;
const StyledHomeIntroSlider = styled.div<{
  isRTL: boolean;
}>`
  .seperator {
    border-right: ${({ isRTL }) => (!isRTL ? "1px solid #f7f7fc" : "unset")};
    border-left: ${({ isRTL }) => (isRTL ? "1px solid #f7f7fc" : "unset")};
  }
`;
