import useTranslation from "next-translate/useTranslation";
import { TUtilities } from "../../customHooks/useUtilities";

import { THomeWidget } from "apiTypes";
import HomeIntroSlider from "components/Home/HomeIntroSlider";
import { NEXT_URL_PROXY } from "config";
import { useState } from "react";
import { toast } from "react-toastify";
import { postData } from "utils/postData";
import useMobile from "../../customHooks/useMobile";
import useModal from "../../customHooks/useModal";
import NewsLetterModal from "../Donate/NewsLetterModal";
import HomeIntroAbout from "../Home/HomeIntroAbout";
import SEO from "../Seo";
import HomeHeroes from "./HomeHeroes";
import HomePageActionCard from "./HomePageActionCard";
import HomeSlider from "./HomeSlider";
import NewHomeCardsSlider from "./NewHomeCardsSlider";
type Props = {
  data: THomeWidget[];
  quick_actions: TUtilities["quick_actions"];
  promoVid?: string;
};
const NewHomePage = ({ data, quick_actions, promoVid }: Props) => {
  const { t } = useTranslation("common");

  const isMobile = useMobile("md");

  const modal = useModal();
  const [isLoading, setIsLoading] = useState(false);

  const onEventSubmit = async (data) => {
    setIsLoading(true);
    const res = await postData(`${NEXT_URL_PROXY}/newsletter-subscribers`, {
      ...data,
    });
    if (res.status === 200) {
      modal.handleCloseModal();
      toast.success(t("subbed_to_newsletter"));
    } else {
      toast.error(t("something_went_wrong"));
    }
    setIsLoading(false);
  };

  return (
    <>
      <SEO />
      {/* <NewHomeIntroSlider promoVid={promoVid} /> */}
      <HomeIntroSlider promoVid={promoVid} />

      <div
        style={{
          background: "rgba(23, 104, 179, 0.08)",
          position: "relative",
          marginBottom: 48
        }}
      >
        {/* <HomeDonateBar /> */}
        <HomeIntroAbout />
      </div>

      {data.map((group, k) => {
        const isLast = k === data.length - 1;
        if (group.type === "slider") {
          return (
            <div
              key={k}
              dir="ltr"
              className={`rounded container `}
              style={{ marginTop: 0 }}
            >
              <HomeSlider slides={group.slides} />
            </div>
          );
        }
        return (
          <div key={k} className="overflow-hidden" style={{ marginTop: 0 }}>
            <NewHomeCardsSlider group={group} />
          </div>
        );
      })}
      {/* <HomeMarquee /> */}

      <HomeHeroes />
      <div
        className="w-100  gap-5 container my-3"
        style={{
          display: "grid",
          gridTemplateColumns: isMobile ? "repeat(1, 1fr)" : "repeat(2, 1fr)",
        }}
      >
        <HomePageActionCard
          title={t("contact_us")}
          desc={t("contact_us_desc")}
          background={"var(--primary2)"}
          icon={"phone"}
          action={{
            label: t("see_contact_options"),
            style: {
              backgroundColor: "#fff",
              color: "var(--primary2)",
              borderColor: "#fff",
            },
            labelClassName: "text-dark",
            variant: "light",
            href: "/help/contact-us",
            onClick: () => {},
          }}
        />
        <HomePageActionCard
          title={t("sub_to_newsletter")}
          desc={t("newsletter_desc")}
          background={"#041A2F"}
          icon={"mail"}
          action={{
            onClick: modal.handleShowModal,
            label: t("subscribe"),
          }}
        />
        <NewsLetterModal
          closeModalOffCanvas={modal.handleCloseModal}
          showModalOffcanvas={modal.showModal}
          onSubmit={onEventSubmit}
          isAdd
          isLoading={isLoading}
        />
      </div>
    </>
  );
};

export default NewHomePage;
